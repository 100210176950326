import { Controller } from 'stimulus';
import $ from 'jquery';
import flatpickr from 'vendor/flatpickr';
import Rails from '@rails/ujs';
import Select2 from "select2";

export default class extends Controller {
  static targets = ["form", "startAt", "category", "typeName", "peopleCount", "select2", "cookoonButlerState", "cookoonPlaceButton", "atHomeButton", "atWorkButton", "addressesModal", "submitButton", "icon"]

  connect() {
    this.initSelect2()
    this.initFlatpickr(this.startAtTarget, JSON.parse(this.startAtTarget.getAttribute("data-dates-unavailable")))

    document.querySelectorAll('.select2-selection--single, .select2-selection__rendered, .select2-selection__arrow').forEach((element) => {
      element.classList.add("search-from-chef");
    })

    $('.select2-container').on('click', (event) => {
      $('.select2-results__options').addClass('search-from-chef')
      $('.select2-results__option').addClass('search-from-chef')
    })

    this.changeDisplayedPeopleCount(document.querySelector('.select2-selection__rendered.search-from-chef'))

    this.submitButtonTarget.addEventListener('mouseover', (event) => {
      this.toggleClass(this.submitButtonTarget, 'bg-white', 'bg-primary')
      this.toggleClass(this.iconTarget, 'text-primary', 'text-white')
      // this.submitLogoTarget.setAttribute("src", this.submitLogoTarget.dataset.logoWhite);
    })

    this.submitButtonTarget.addEventListener('mouseout', (event) => {
      this.toggleClass(this.submitButtonTarget, 'bg-primary', 'bg-white')
      this.toggleClass(this.iconTarget, 'text-white', 'text-primary')
      // this.submitLogoTarget.setAttribute("src", this.submitLogoTarget.dataset.logoBold);
    })

    this.submitButtonTarget.addEventListener('click', (event) => {
      Rails.fire(this.formTarget, 'submit')
    })

    $(this.typeNameTarget).on('change', (event) => {
      this.refreshFlatpickr()
    })
  }

  changeDisplayedPeopleCount = (element) => {
    element.innerHTML = element.innerHTML.split(' ')[0]
  }

  changeDisplayedDate = (element) => {
    element.value = "Votre choix"
  }

  initFlatpickr = (input, datesUnavailable) => {
    flatpickr(input, {
      disableMobile: "true",
      altInput: true,
      altFormat: "D j F Y",
      dateFormat: 'Y-m-d',
      defaultDate: input.value,
      minDate: JSON.parse(input.getAttribute("data-start-at-available")),
      maxDate: JSON.parse(input.getAttribute("data-end-at-available")),
      disable: datesUnavailable,
    })
    if (this.startAtTarget.value === "") {
      this.changeDisplayedDate(this.startAtTarget.parentElement.childNodes[1])
    }
  }

  refreshFlatpickr = () => {
    const typeName = this.typeNameTarget.value
    const startDateAvailable = this.startAtTarget.getAttribute("data-start-at-available")
    const endDateAvailable = this.startAtTarget.getAttribute("data-end-at-available")

    const url = `${this.typeNameTarget.dataset.url}?type_name=${typeName}&start_date_available=${startDateAvailable}&end_date_available=${endDateAvailable}`
    fetch(url)
      .then(response => response.json())
      .then((data) => {
        this.initFlatpickr(this.startAtTarget, data["datesUnavailable"])
      })
  }

  initSelect2() {
    this.select2Targets.forEach((select2) => {
      $(select2).select2({
        minimumResultsForSearch: -1
      })
    })
  }

  setCategoryAndCookoonButlerState() {
    const button = event.currentTarget
    if (button === this.cookoonPlaceButtonTarget) {
      this.categoryTarget.value = "customer"
      this.cookoonButlerStateTarget.value = "initial"
      this.toggleClass(this.cookoonPlaceButtonTarget, "btn-cookoon-secondary-gold", "btn-cookoon-primary")
      this.toggleClass(document.querySelector('[data-target="#addresses"]'), "btn-cookoon-primary", "btn-cookoon-secondary-gold")
    } else if (button === this.atHomeButtonTarget) {
      this.categoryTarget.value = "customer"
      this.cookoonButlerStateTarget.value = "none"
      this.toggleClass(this.cookoonPlaceButtonTarget, "btn-cookoon-primary", "btn-cookoon-secondary-gold")
      this.toggleClass(document.querySelector('[data-target="#addresses"]'), "btn-cookoon-secondary-gold", "btn-cookoon-primary")
      $(this.addressesModalTarget).modal('hide');
    } else if (button === this.atWorkButtonTarget) {
      this.categoryTarget.value = "business"
      this.cookoonButlerStateTarget.value = "none"
      this.toggleClass(this.cookoonPlaceButtonTarget, "btn-cookoon-primary", "btn-cookoon-secondary-gold")
      this.toggleClass(document.querySelector('[data-target="#addresses"]'), "btn-cookoon-secondary-gold", "btn-cookoon-primary")
      $(this.addressesModalTarget).modal('hide');
    }
  }

  toggleClass = (element, classOne, classTwo) => {
    if (element.classList.contains(classOne)) {
      element.classList.remove(classOne)
    }
    element.classList.add(classTwo)
  }
}
