import { Controller } from 'stimulus';
import { French } from "flatpickr/dist/l10n/fr.js"

export default class extends Controller {
  static targets = ['select2', 'form', 'typeName', 'peopleCount', 'spot', 'date']

  connect() {
    this.initSelect2()
    this.initFlatpickr()
  }

  disconnect() {
    if (this.hasSelect2Target) {
      this.select2Targets.forEach((select2) => {
        $(select2).select2('destroy')
      })
    }
    if (this.hasDateTarget) {
      this.dateTargets.forEach((date) => {
        flatpickr(date).destroy();
      })
    }
  }

  initSelect2() {
    if (this.hasSelect2Target) {
      this.select2Targets.forEach((select2) => {
        $(select2).select2({
          minimumResultsForSearch: -1,
        })
      })
    }
  }

  initFlatpickr = () => {
    if (this.hasDateTarget) {
      flatpickr(this.dateTarget, {
        locale: French,
        altInput: true,
        altFormat: 'D j M',
        dateFormat: 'Y-m-d',
        disable: JSON.parse(this.dateTarget.getAttribute("data-dates-unavailable")),
        minDate: this.dateTarget.getAttribute("data-start-date-available"),
        // minDate: 'today',
        // minDate: new Date().fp_incr(3),
        // maxDate: new Date().fp_incr(365),
        maxDate: this.dateTarget.getAttribute("data-end-date-available"),
        disableMobile: "true",
      })
    }
  }
}
